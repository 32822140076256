<template>
  <el-dialog
      custom-class="payment-dialog"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
  >
    <div class="icon">
      <img src="@/assets/images/icons/badge.png" alt="">
    </div>
    <div class="close" @click="closePaymentDialog">
      <img src="@/assets/images/btn/payment_close.png" alt="">
    </div>
    <div class="content">
      <div class="content-title">购买物品：<span>{{ materialInfo.materialName }}</span></div>
      <div class="content-info">
        <div class="content-info-left">
          <div class="tab"
               :style="{border: payParams.payType === item.type?'1px solid #FC7228': 'none'}"
               v-for="(item,index) in paymentType" :key="index"
               @click="selectPaymentType(item)"
          >
            <div class="tab-icon">
              <img v-if="item.name === 'alipay'" src="@/assets/images/logo/zfb_logo.png" alt="">
              <img v-else src="@/assets/images/logo/wx_logo.png" alt="">
            </div>
            <span>{{ item.name === "alipay" ? "支付宝" : "微信" }}</span>
            <div class="tab-check" v-show="payParams.payType === item.type">
              <img src="@/assets/images/btn/payment_check.png" alt="">
            </div>
          </div>
        </div>

        <div class="content-info-middle" v-if="!isLoading">
          <vue-qr :text="payCode" :size="155"></vue-qr>
        </div>

        <div class="content-info-middle" v-if="isLoading">
          <i class="el-icon-loading"></i>
        </div>

        <div class="content-info-right">
          <div class="price">
            应付金额：
            <span class="price-icon">￥</span>
            <span class="price-num">{{ priceInfo.price }}</span>
          </div>
          <div class="discount">已优惠：￥0</div>
          <div class="contact">
            <p>遇到问题？联系客服</p>
            <div class="contact-box">
              <div class="code">
                <img src="../../assets/images/material/code.png" alt="">
              </div>
            </div>
          </div>
          <div class="complete">
            <button @click="onCompletePayment">已完成支付</button>
          </div>
        </div>
      </div>
      <div class="content-balance" v-show="false">
        <span>账户余额: 0首币</span>
        <span class="content-balance-msg">您的币点余额不足，</span>
        <span class="content-balance-rcg">立即充值</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import VueQr from 'vue-qr';
import homeApi from "@/api/home";

export default {
  name: 'Payment',
  components: {
    VueQr
  },
  data() {
    return {
      visible: false,
      materialInfo: '', //素材信息
      priceInfo: '', //素材价格信息
      payParams: '', //创建订单参数
      paymentType: [{ //支付类型
        name: 'alipay',
        type: 1
      },{
        name: 'wxpay',
        type: 2
      }],
      payCode: '', //二维码
      orderNo: '', //订单编号
      isLoading: false
    }
  },
  mounted() {

  },
  methods: {
    // 选择支付方式
    selectPaymentType(item) {
      this.payParams.payType = item.type;
      this.onCreateOrder();
    },
    // 创建订单
    onCreateOrder() {
      this.isLoading = true;
      homeApi.postCreateOrder(this.payParams).then((res) => {
        this.$parent.loadingInstance.close();
        if (res.data.code === 200) {
          this.visible = true; //打开购买弹框
          this.payCode = res.data.data.paymentTypeList[0].payUrl;
          this.orderNo = res.data.data.tradeInfo.orderNo;
          this.isLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.isLoading = false;
        }
      })
    },
    // 完成支付
    onCompletePayment() {
      homeApi.postCheckPayment({
        orderNo: this.orderNo,
        userId: this.$store.state.userId,
        appId: 1, //订单来源
      }).then((res) => {
        if (res.data.data.isPay === 0) {
          this.$message.error("您暂未完成支付");
        }
        if (res.data.data.isPay === 1) {
          this.$message.success("支付成功");
          this.$parent.getMaterialInfo();
          this.visible = false;
        }
      })
    },
    // 关闭支付弹框
    closePaymentDialog() {
      this.visible = false;
      homeApi.postCheckPayment({
        orderNo: this.orderNo,
        userId: this.$store.state.userId,
        appId: 1, //订单来源
      }).then((res) => {
        if (res.data.data.isPay === 1) {
          this.$message.success("支付成功");
          this.$parent.getMaterialInfo();
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .payment-dialog {
  width: 653px;
  height: 653px;
  background: url("../../assets/images/home/payment_bg.png") no-repeat;
  background-size: 100% 100%;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    .icon {
      width: 178px;
      height: 178px;
      position: absolute;
      top: 87px;
      right: 237px;
      z-index: 10;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      width: 631px;
      height: 302px;
      background: #FFFFFF;
      padding: 48px 66px;
      box-sizing: border-box;
      border-radius: 13px;
      position: absolute;
      left: 11px;
      right: 11px;
      top: 249px;

      .content-title {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #935838;

        span {
          font-size: 16px;
          font-weight: 500;
          color: #935838;
        }
      }

      .content-info {
        width: 100%;
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content-info-left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .tab {
            width: 110px;
            height: 35px;
            margin-bottom: 8px;
            border-radius: 4px;
            padding-left: 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            position: relative;

            .tab-icon {
              width: 20px;
              height: 20px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            span {
              margin-left: 6px;
              font-size: 14px;
              font-weight: 600;
              color: #979797;
              line-height: 35px;
            }

            .tab-check {
              width: 15px;
              height: 13px;
              position: absolute;
              right: -2px;
              bottom: 1px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .content-info-middle {
          width: 140px;
          height: 140px;
          border: 1px solid #EAEAEA;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
        }

        .content-info-right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .price {
            font-size: 12px;
            font-weight: 400;
            color: #14141C;
            line-height: 30px;

            .price-icon {
              font-size: 16px;
              font-weight: 600;
              color: #FF6700;
            }

            .price-num {
              font-size: 28px;
              font-weight: 600;
              color: #FF6700;
            }
          }

          .discount {
            font-size: 12px;
            font-weight: 400;
            color: #14141C;
            line-height: 30px;
          }

          .contact {
            position: relative;

            p {
              font-size: 12px;
              font-weight: 400;
              color: #14141C;
              cursor: pointer;
              line-height: 30px;
            }

            .contact-box {
              width: 114px;
              height: 163px;
              background: url("../../assets/images/home/kefu_bg.png") no-repeat;
              background-size: 100% 100%;
              position: absolute;
              bottom: 30px;
              right: -60px;
              display: none;

              .code {
                width: 72px;
                height: 72px;
                position: absolute;
                top: 71px;
                left: 50%;
                transform: translateX(-50%);

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .contact:hover {
            p {
              color: #FF6700;
            }

            .contact-box {
              display: block;
            }
          }

          .complete {
            margin: 6px 0;
            line-height: 30px;

            button {
              padding: 7px 10px;
              box-sizing: border-box;
              background-color: #FF6700;
              border: none;
              border-radius: 5px;
              font-size: 14px;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
        }
      }

      .content-balance {
        width: 100%;
        margin-top: 15px;
        text-align: left;

        span {
          font-size: 12px;
          font-weight: 500;
          color: #979797;
        }

        .content-balance-msg {
          margin-left: 10px;
          color: #F93535;
        }

        .content-balance-rcg {
          color: #04CD65;
          cursor: pointer;
        }
      }
    }

    .close {
      width: 52px;
      height: 52px;
      position: absolute;
      top: 104px;
      right: -26px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>