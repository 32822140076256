<template>
  <div id="details">
    <!-- 详情页头部 -->
    <div class="header">
      <div class="header-content">
        <div class="header-l">
          <router-link
              :to="{
            path: '/',
            query: {
              name: 'SHOWINGS首映式',
            },
          }"
          >
            <div class="header-l-logo">
              <img src="@/assets/images/logo/logo.png" alt=""/>
            </div>
          </router-link>
          <p class="header-l-name">{{ materialInfo.materialName }}</p>
        </div>
        <div class="header-r" v-if="!$store.state.userId && priceInfo.price > 0" @click="operationEvent">立即购买</div>
        <div class="header-r" v-if="!$store.state.userId && priceInfo.price === 0" @click="operationEvent">立即下载</div>
        <div class="header-r" v-if="materialInfo.isDownload === 0 && priceInfo.price > 0" @click="operationEvent">立即购买</div>
        <div class="header-r" v-if="materialInfo.isDownload === 1 || (materialInfo.isDownload === 0 && priceInfo.price === 0)" @click="operationEvent">立即下载</div>
      </div>
    </div>
    <!-- 内容部分 -->
    <div class="content">
      <!-- 素材详情 -->
      <div class="detail" v-if="!isEmpty">
        <div class="detail-tit">内容详情</div>
        <div class="detail-info">
          <div class="info-l">
            <div class="info-l-show">
              <video
                  id="videoPlay"
                  v-show="isPlay"
                  :src="showMaterial"
                  ref="materialVideo"
                  controlsList="nodownload"
                  controls
                  loop
                  muted
              ></video>
              <img
                  v-show="!isPlay"
                  :src="showMaterial.indexOf('.mp4') > -1
                  ? materialInfo.cover
                  : showMaterial
              "
                  alt=""
              />
              <div class="play" v-show="showMaterial.indexOf('.mp4') > -1 && !isPlay" @click.stop="onPlayVideo()">
                <img src="../../assets/images/icons/play.png" alt=""/>
              </div>
            </div>
            <div class="info-l-list">
              <div class="list-left" @click="moveShowList('left')">
                <img src="../../assets/images/icons/icon3.png" alt=""/>
              </div>
              <div class="list-center">
                <div
                    :style="{ transform: 'translateX(' + moveWidth + 'px)' }"
                    class="list-center-show"
                    v-for="(item,index) in showList"
                    :key="index"
                    @click="onChoiceMaterial(item.picturePath)"
                >
                  <img
                      :style="{
                    opacity: showMaterial == item.picturePath ? 1 : 0.5,
                  }"
                      :src="item.picturePath.indexOf('.mp4') > -1
                      ? materialInfo.cover
                      : item.picturePath
                  "
                      alt=""
                  />
                </div>
              </div>
              <div class="list-right" @click="moveShowList('right')">
                <img src="../../assets/images/icons/icon2.png" alt=""/>
              </div>
            </div>
          </div>
          <div class="info-r">
            <div class="info-r-name">{{ materialInfo.materialName }}</div>
            <div class="info-r-category">
              <span>{{ materialInfo.categoryName }}</span>
            </div>
            <div class="info-r-number">
              <div class="time">
                <div class="time-icon">
                  <img src="@/assets/images/icons/time.png" alt=""/>
                </div>
                <div class="time-number">{{ materialInfo.uploadTime }}</div>
              </div>
              <div class="buttons">
                <div class="icon">
                  <img
                      v-show="
                    !materialInfo.isCollect || materialInfo.isCollect == 0
                  "
                      src="@/assets/images/icons/icon8.png"
                      alt=""
                      @click="openAddDialog(materialInfo)"
                  />
                  <img
                      v-show="materialInfo.isCollect == 1"
                      src="@/assets/images/icons/icon9.png"
                      alt=""
                      @click="onCancelCollection(materialInfo)"
                  />
                </div>
                <div
                    class="icon"
                    @click="onLike(materialInfo.isLike, materialInfo)"
                >
                  <img
                      :src="
                    !materialInfo.isLike || materialInfo.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png')
                  "
                      alt=""
                  />
                </div>
              </div>
            </div>
            <div class="info-r-detail">
              <div class="detail-l">
                <div class="detail-l-price">
                  <span class="price-icon">￥</span>
                  <span class="price-num">{{ priceInfo.price }}</span>
                </div>
                <div class="detail-l-btn">
                  <div class="download" v-if="!$store.state.userId && priceInfo.price > 0" @click="operationEvent">立即购买</div>
                  <div class="download" v-if="!$store.state.userId && priceInfo.price === 0" @click="operationEvent">立即下载</div>
                  <div class="download" v-if="materialInfo.isDownload === 0 && priceInfo.price > 0" @click="operationEvent">立即购买</div>
                  <div class="download" v-if="materialInfo.isDownload === 1 || (materialInfo.isDownload === 0 && priceInfo.price === 0)" @click="operationEvent">立即下载</div>
                </div>
              </div>
              <div class="detail-r">
                <div class="detail-r-plate">
                  <p class="plate-title">支持的平台</p>
                  <div class="plate-list" v-for="(item, index) in materialInfo.supportPlatform" :key="index">
                    <p>{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="info-r-user">
              <div class="user">
                <div class="user-info">
                  <div class="user-info-avatar" @click="jumpPersonalPage()">
                    <img :src="authorInfo.avatar" alt=""/>
                  </div>
                  <div class="user-info-text">
                    <div class="name">{{ authorInfo.nickName }}</div>
                    <div class="autograph">{{ authorInfo.motto }}</div>
                  </div>
                </div>
                <div
                    class="user-noSubscribe"
                    v-if="authorInfo.isSubscribe == 0"
                    @click="onAddAttention()"
                >
                  +关注
                </div>
                <div
                    class="user-isSubscribe"
                    v-if="authorInfo.isSubscribe == 1"
                    @click="onCancelAttention()"
                >
                  已关注
                </div>
              </div>
              <div class="works">
                <div
                    class="work"
                    v-for="(item, index) in authorInfo.materialList"
                    :key="index"
                    @click="jumpDetailsPage(item.materialName, item.materialId)"
                >
                  <img :src="item.cover" alt=""/>
                </div>
              </div>
              <div class="show">
              <span class="num" v-if="authorInfo.fansNumber !== 0">{{
                  authorInfo.fansNumber
                }}</span>
                {{ authorInfo.fansNumber !== 0 ? "位粉丝," : "" }}
                <span class="num">{{ authorInfo.worksNumber }}</span>
                个作品
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 推荐内容 -->
      <div class="recommend" v-if="!isEmpty">
        <div class="recommend-title" v-if="recommendList.length > 0">
          <div class="recommend-title-l">推荐素材</div>
        </div>
        <div class="recommend-area">
          <div
              class="recommend-box"
              v-for="item in recommendList"
              :key="item.materialInfoVO.materialId"
          >
            <div
                class="recommend-box-show"
                @mouseenter="item.materialInfoVO.show = true"
                @mouseleave="item.materialInfoVO.show = false"
                @click.stop="
            jumpDetailsPage(
              item.materialInfoVO.materialName,
              item.materialInfoVO.materialId
            )
          "
            >
              <div class="show-image" v-show="!item.materialInfoVO.show">
                <img v-lazy="item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
              </div>
              <div class="show-image" v-if="item.materialInfoVO.show">
                <img :src="getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
                <div class="buttons">
                  <div
                      class="buttons-collection"
                      v-show="
                  !item.materialInfoVO.isCollect ||
                  item.materialInfoVO.isCollect == 0
                "
                      @click.stop="openAddDialog(item.materialInfoVO)"
                  >
                    收藏
                  </div>
                  <div
                      class="buttons-iscollection"
                      v-show="item.materialInfoVO.isCollect == 1"
                      @click.stop="onCancelCollection(item.materialInfoVO)"
                  >
                    已收藏
                  </div>
                  <div
                      class="buttons-like"
                      @click.stop="
                  onLike(item.materialInfoVO.isLike, item.materialInfoVO)
                "
                  >
                    <img
                        :src="
                    !item.materialInfoVO.isLike ||
                    item.materialInfoVO.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png')
                  "
                        alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="recommend-box-info">
              <div class="info-title">{{ item.materialInfoVO.materialName }}</div>
<!--              <div class="info-avatar">-->
<!--                <img :src="item.authorVo.avatar" alt=""/>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!-- 素材下架 -->
      <div class="offline" v-if="isEmpty">
        <div class="offline-icon">
          <img src="@/assets/images/icons/offline_icon.png" alt="">
        </div>
        <p>该场景已下架</p>
      </div>
    </div>

    <!-- 添加收藏弹出框 -->
    <Favorites ref="favorites"></Favorites>

    <!--登录弹框-->
    <Login ref="login"></Login>

    <!--注册弹框-->
    <Register ref="register"></Register>

    <!--支付弹框-->
    <Payment ref="payment"></Payment>
  </div>
</template>

<script>
import homeApi from "@/api/home.js";
import userApi from "@/api/user.js";
import {Loading} from "element-ui";
import Payment from "@/components/Payment/Payment.vue";
import Login from "@/components/Login/Login.vue";
import Register from "@/components/Register/Register.vue";
import Favorites from "@/components/Favorites/Favorites.vue";

export default {
  components: {
    Payment,
    Login,
    Register,
    Favorites
  },
  data() {
    return {
      materialId: "", //当前素材id
      authorInfo: "", //作者信息
      materialInfo: "", //素材信息
      priceInfo: "", //价格信息
      isPlay: false, //素材视频是否播放
      showList: [], //展示列表
      showMaterial: "", //当前展示的素材
      moveWidth: 0, //素材列表移动宽度
      recommendList: [], //推荐素材
      loadingInstance: '', //订单蒙层对象
      isEmpty: false, //空内容
    };
  },
  created() {
    this.materialId = this.$route.query.material;
    this.getMaterialInfo(); //获取素材详情
    this.getRecommendList(); //获取推荐列表
  },
  methods: {
    // 获取素材详情
    getMaterialInfo() {
      homeApi.postMaterialInfoData({
        materialId: this.materialId,
        userId: this.$store.state.userId ? this.$store.state.userId : null,
      }).then((res) => {
        if (!res.data.data) {
          this.isEmpty = true;
        }else{
          this.authorInfo = res.data.data.authorVo;
          this.materialInfo = res.data.data.materialInfoVO;
          this.priceInfo = res.data.data.materialPriceVO;
          this.showList = res.data.data.pictureVOList;
          if (this.showList.length > 0) {
            this.showMaterial = this.showList[0].picturePath;
          }
        }
      });
    },
    // 获取推荐列表
    getRecommendList() {
      homeApi.postRecommendData({
        materialId: this.materialId,
        userId: this.$store.state.userId ? this.$store.state.userId : null,
      }).then(res => {
        this.recommendList = res.data.data;
      })
    },
    // 选择展示素材
    onChoiceMaterial(url) {
      if (this.showMaterial !== url) {
        this.$refs.materialVideo.pause();
        this.isPlay = false;
        this.showMaterial = url;
      }
    },
    // 播放视频
    onPlayVideo() {
      this.isPlay = true;
      this.$refs.materialVideo.play();
    },
    // 跳转素材详情页
    jumpDetailsPage(name, material) {
      let routeData = this.$router.resolve({
        path: "/home/details",
        query: {
          name,
          material,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 打开添加收藏弹框
    openAddDialog(item) {
      if (this.$store.state.userInfo) {
        this.$refs.favorites.staging = item;
        this.$refs.favorites.visible = true;
        this.$refs.favorites.collectionParams.materialId = item.materialId;
        this.$refs.favorites.getFavoritesData();
      } else {
        this.openLoginDialog();
      }
    },
    // 取消收藏
    onCancelCollection(item) {
      this.$refs.favorites.onCancelCollection(item);
    },
    // 关注
    onAddAttention() {
      if (this.$store.state.userInfo) {
        userApi
          .postAddAttention({
            beSubscribe: this.authorInfo.authorId,
            status: 0,
            subscribe: this.$store.state.userId,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.authorInfo.isSubscribe = 1;
              this.authorInfo.fansNumber++;
            }
          });
      } else {
        this.openLoginDialog();
      }
    },
    // 取消关注
    onCancelAttention() {
      userApi
        .postCancelAttention({
          beSubscribe: this.authorInfo.authorId,
          status: 1,
          subscribe: this.$store.state.userId,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.authorInfo.isSubscribe = 0;
            this.authorInfo.fansNumber--;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 点赞/取消点赞
    onLike(num, item) {
      if (this.$store.state.userInfo) {
        if (num == 0) {
          userApi
            .postAddLike({
              materialId: item.materialId,
              userId: this.$store.state.userId,
            })
            .then((res) => {
              if (res.data.code == 200) {
                item.isLike = 1;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          userApi
            .postCancelLike({
              materialId: item.materialId,
              userId: this.$store.state.userId,
            })
            .then((res) => {
              if (res.data.code == 200) {
                item.isLike = 0;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }
      } else {
        this.openLoginDialog();
      }
    },
    // 跳转个人页
    jumpPersonalPage() {
      if (this.$store.state.userInfo) {
        window.localStorage.setItem('tabIndex', 1);
        let routeData = this.$router.resolve({
          path: "/user/personal",
          query: {
            name: "ta的主页",
            id: this.authorInfo.authorId,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.openLoginDialog();
      }
    },
    // 移动展示列表
    moveShowList(direction) {
      if (this.showList.length > 4) {
        if (direction === "left" && this.moveWidth < 0) {
          this.moveWidth = this.moveWidth + 160;
        } else if (
            direction === "right" &&
            this.moveWidth >= -(this.showList.length - 5) * 160
        ) {
          this.moveWidth = this.moveWidth - 160;
        }
      }
    },
    // 打开登录弹框
    openLoginDialog() {
      this.$refs.login.phoneNumber = "";
      this.$refs.login.verificationCode = "";
      this.$refs.login.password = "";
      clearInterval(this.$refs.login.timer);
      this.$refs.login.time = 60;
      this.$refs.login.timer = null;
      this.$refs.login.isRegister = false;
      this.$refs.login.isPassword = false;
      this.$refs.register.visible = false;
      this.$refs.login.visible = true;
    },
    // 打开注册弹框
    openRegisterDialog(boolean) {
      this.$refs.register.phoneNumber = "";
      this.$refs.register.verificationCode = "";
      this.$refs.register.password = "";
      clearInterval(this.$refs.register.timer);
      this.$refs.register.time = 60;
      this.$refs.register.timer = null;
      this.$refs.login.visible = false;
      if (boolean) {
        this.$refs.register.isRegister = false;
      } else {
        this.$refs.register.isRegister = true;
      }
      this.$refs.register.visible = true;
    },
    // 购买/下载操作
    operationEvent() {
      if (this.$store.state.userInfo) {
        if (this.materialInfo.isDownload === 0 && this.priceInfo.price > 0) {
          this.onVerifyOrder(); //校验下单
        } else {
          this.handleDownload(); //下载素材
        }
      } else {
        this.openLoginDialog();
      }
    },
    // 校验下单
    onVerifyOrder() {
      this.loadingInstance = Loading.service({
        lock: true,
        text: "创建订单中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      homeApi.postCheckPayment({
        materialId:this.materialInfo.materialId,
        userId: this.$store.state.userId,
        appId: 1
      }).then((res) => {
        if (res.data.data.isPay === 1) {
          this.loadingInstance.close();
          this.$message.success("您已经购买过该素材了");
          this.getMaterialInfo(); //获取素材详情
        } else {
          this.$refs.payment.payParams = {
            goodsIds: [this.materialInfo.materialId], //素材ID
            appId: 1, //订单来源
            userId: this.$store.state.userId,
            remark: "购买" + this.materialInfo.materialName,
            payType: 1
          }
          this.$refs.payment.priceInfo = this.priceInfo;
          this.$refs.payment.materialInfo = this.materialInfo;
          this.$refs.payment.onCreateOrder(); //创建订单
        }
      })
    },
    // 下载素材
    handleDownload() {
      homeApi.postDownload({
        userId: this.$store.state.userId,
        fileId: this.materialInfo.materialId,
      }).then((res) => {
        if (res.data.code == 200) {
          window.open(res.data.data.packageUrl);
          // this.download(
          //   res.data.data.packageUrl,
          //   this.materialInfo.materialName
          // );
        }
      });
    },
    // url下载
    download(url, name) {
      const link = document.createElement("a");
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          link.href = URL.createObjectURL(blob);
          link.download = name;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        });
    },
  },
};
</script>

<style lang="less" scoped>
#details {
  width: 100%;

  .header {
    width: 100%;
    background-color: #010301;
    position: sticky;
    top: 0;
    z-index: 999;

    .header-content {
      max-width: 1600px;
      min-width: 1200px;
      height: 80px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-l {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .header-l-logo {
          width: 233px;
          height: 51px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .header-l-name {
          margin-left: 94px;
          font-size: 26px;
          font-weight: 400;
          color: #f9f9f9;
        }
      }

      .header-r {
        width: 200px;
        height: 55px;
        background-color: #fff429;
        line-height: 55px;
        border-radius: 5px;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: #1c231f;
        cursor: pointer;
      }
    }
  }

  .content {
    width: 100%;
    padding-bottom: 60px;
    box-sizing: border-box;

    .detail {
      width: 1200px;
      margin: 0 auto;

      .detail-tit {
        background: linear-gradient(to right,
        rgba(255, 255, 255, 1),
        rgba(157, 152, 253, 0.6));
        background-clip: text;
        color: transparent;
        display: inline-block;
        text-align: left;
        margin: 50px 0 30px;
        box-sizing: border-box;
        font-size: 28px;
        font-weight: 700;
      }

      .detail-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-l {
          width: 752px;
          height: 513px;

          .info-l-show {
            width: 100%;
            height: 423px;
            background: rgba(1, 0, 1, 1);
            position: relative;

            video {
              width: 100%;
              height: 100%;
            }

            img {
              width: 100%;
              height: 100%;
            }

            img[src=""],
            img:not([src]) {
              opacity: 0;
            }

            .play {
              width: 55px;
              height: 55px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .info-l-list {
            width: 100%;
            height: 90px;
            display: flex;
            justify-content: space-between;

            .list-left {
              width: 56px;
              height: 90px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .list-center {
              width: calc(100% - 112px);
              white-space: nowrap;
              overflow: hidden;
              // overflow-x: auto;

              .list-center-show {
                width: 160px;
                height: 90px;
                display: inline-block;
                cursor: pointer;
                // transition: 0.3;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .list-right {
              width: 56px;
              height: 90px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .info-r {
          width: 408px;
          // height: 513px;

          .info-r-name {
            width: 100%;
            font-size: 26px;
            font-weight: 400;
            color: #f9f9f9;
          }

          .info-r-category {
            margin-top: 5px;
            font-size: 18px;
            font-weight: 400;
            color: #b8b8b8;

            span {
              color: #03bd61;
            }
          }

          .info-r-number {
            width: 100%;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .time-icon {
                width: 18px;
                height: 20px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .time-number {
                margin-left: 8px;
                font-size: 18px;
                font-weight: 400;
                color: #676767;
              }
            }

            .buttons {
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .icon {
                width: 40px;
                height: 40px;
                margin-left: 15px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .info-r-detail {
            width: 100%;
            height: 165px;
            margin-top: 12px;
            background: linear-gradient(134deg,
            rgba(238, 238, 238, 0.1),
            rgba(216, 216, 216, 0.2));
            border-radius: 1px;
            display: flex;
            justify-content: space-between;

            .detail-l {
              width: 50%;
              height: 100%;
              border-right: 2px solid #444645;
              padding: 40px 22px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              .detail-l-price {
                width: 100%;
                text-align: center;

                .price-icon {
                  font-size: 24px;
                  font-weight: 400;
                  color: #f9f9f9;
                }

                .price-num {
                  font-size: 24px;
                  font-weight: 600;
                  color: #fff429;
                }
              }

              .detail-l-btn {
                width: 120px;
                height: 45px;
                background: #fff429;
                border-radius: 5px;
                text-align: center;
                line-height: 45px;
                font-size: 18px;
                font-weight: 600;
                color: #212523;
                cursor: pointer;
              }
            }

            .detail-r {
              width: 50%;
              height: 100%;
              padding: 15px 22px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;

              .detail-r-plate {
                width: 100%;

                .plate-title {
                  padding: 2px 0;
                  box-sizing: border-box;
                  font-size: 14px;
                  font-weight: 400;
                  color: #f9f9f9;
                }

                .plate-list {
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  p {
                    padding: 2px 0;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-weight: 400;
                    color: #f9f9f9;
                  }
                }
              }
            }
          }

          .info-r-user {
            width: 100%;
            height: 204px;
            margin-top: 18px;
            padding: 20px 25px;
            box-sizing: border-box;
            background: linear-gradient(134deg,
            rgba(238, 238, 238, 0.1),
            rgba(216, 216, 216, 0.2));
            border-radius: 1px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .user {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .user-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .user-info-avatar {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  overflow: hidden;
                  cursor: pointer;

                  img {
                    width: 100%;
                    height: 100%;
                  }

                  img[src=""],
                  img:not([src]) {
                    opacity: 0;
                  }
                }

                .user-info-text {
                  margin-left: 14px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  .name {
                    font-size: 16px;
                    font-weight: 600;
                    color: #f9f9f9;
                  }

                  .autograph {
                    width: 196px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #676767;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }

              .user-noSubscribe {
                width: 85px;
                height: 35px;
                background: #676767;
                border-radius: 5px;
                text-align: center;
                line-height: 35px;
                font-size: 16px;
                font-weight: 400;
                color: #f9f9f9;
                cursor: pointer;
              }

              .user-isSubscribe {
                width: 85px;
                height: 35px;
                background-color: #fff429;
                border-radius: 5px;
                text-align: center;
                line-height: 35px;
                font-size: 16px;
                font-weight: 400;
                color: #212523;
                cursor: pointer;
              }
            }

            .works {
              width: 100%;
              display: flex;
              justify-content: flex-start;

              .work {
                width: 112px;
                height: 63px;
                margin-right: 11px;
                border-radius: 5px;
                overflow: hidden;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .works :nth-child(3) {
              margin-right: 0;
            }

            .show {
              width: 201px;
              height: 33px;
              background: #474747;
              border-radius: 4px;
              text-align: center;
              line-height: 33px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;

              .num {
                color: #03bd61;
              }
            }
          }
        }
      }
    }

    .recommend {
      width: 1200px;
      margin: 0 auto;

      .recommend-title {
        width: 100%;
        padding: 65px 0 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .recommend-title-l {
          width: 150px;
          height: 55px;
          background: linear-gradient(134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%);
          border: 2px solid;
          border-image: linear-gradient(311deg,
          rgba(157, 152, 253, 1),
          rgba(255, 255, 255, 1)) 2 2;
          backdrop-filter: blur(30px);
          color: #f9f9f9;
          text-align: center;
          line-height: 55px;
          font-size: 24px;
        }
      }

      .recommend-area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .recommend-box {
          // width: 32%;
          // width: 380px;
          // height: 240px;
          // margin-right: 2%;
          width: 384px;
          height: 216px;
          margin-right: 24px;
          margin-bottom: 30px;
          border-radius: 10px;
          cursor: pointer;
          position: relative;

          .recommend-box-show {
            width: 100%;
            height: 100%;

            .show-image {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                //width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .buttons {
                width: 100%;
                padding: 10px 16px 0;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;

                .buttons-collection {
                  width: 65px;
                  height: 38px;
                  background: #fff429;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 38px;
                  font-size: 16px;
                  color: #000000;
                }

                .buttons-iscollection {
                  width: 65px;
                  height: 38px;
                  background: #343736;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 38px;
                  font-size: 16px;
                  color: #ffffff;
                }

                .buttons-like {
                  width: 38px;
                  height: 38px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }

          .recommend-box-info {
            width: 100%;
            padding: 0 16px 10px;
            box-sizing: border-box;
            border-radius: 0 0 10px 10px;
            background: linear-gradient(180deg,
            rgba(29, 33, 41, 0) 0,
            rgba(29, 33, 41, 0.8) 100%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 0;

            .info-title {
              width: calc(100% - 80px);
              font-size: 18px;
              font-weight: 400;
              color: #f9f9f9;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .info-avatar {
              width: 38px;
              height: 38px;
              border-radius: 50%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }

              img[src=""],
              img:not([src]) {
                opacity: 0;
              }
            }
          }
        }
      }

      .recommend-area .recommend-box:nth-of-type(3n) {
        margin-right: 0;
      }

      .recommend-area .recommend-box:nth-of-type(n + 4) {
        margin-bottom: 0;
      }
    }
  }



  .offline {
    width: 1200px;
    height: calc(100vh - 290px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .offline-icon {
      width: 540px;
      height: 344px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    p {
      font-size: 24px;
      color: #616468;
    }
  }
}
</style>
